.contact {
    padding: 1rem 2rem;
    position: relative;
    
}

.contact-title {
    font-size: 1rem;
    font-weight: 700;
    color: var(--secondary-color);
}
.contact-location {
    font-size:30px;
    color: var(--text-color);
    font-weight: 600;
}
.contact-venue {
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
    color: var(--secondary-color);
}
.contact-dateAndTime {
    font-family: 'Roboto',sans-serif;
    padding-top: 1rem;
    display: flex;
    column-gap: 30px;
    font-size: 16px;
    font-weight: 200;
    color: var(--secondary-color);
}
@media (max-width: 640px) {
    .contact-title {
        font-size: 0.5rem;
        font-weight: 400;
    }
    .contact-location {
        font-size: 1.5rem;
        font-weight: 600;
    }
    .contact-venue {
        font-size: .8rem;
        font-weight: 300;
    }
}
