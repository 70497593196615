.trackinfo-title{
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 40px;
    color: white;
    padding: 1em;
}


.trackinfo{
    padding: 2rem;
    line-height: 1.6;
    color: gray;
    text-align: center;   
}
.track{
    margin-bottom: 30px;
    margin-top: 1rem;
}

.trackinfo-heading{
    color: #324273;
    font-size: 36px;
    font-weight: 800;
}
.trackinfo-chair{
    font-size: 24px;
    font-weight: 800;
    color: #1e73be;
    margin: 25px 0px;
}
.trackinfo-content{
    font-family: var(--font-family-contents);
    margin-top: 30px;
    font-size: 16px;
    font-weight: 300;
    color:var(--font-color);
    align-self:flex-start;
}
@media(max-width:640px){
    .trackinfo-title{
        font-size: 30px;
        padding: 0;
    }

    .trackinfo-heading{
       font-size: 29px;
    }
}
