.speakers {
    width: 100%;
}

.speakers-title {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: white;
    padding: 1em;
}

.speakers-trackchair {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    font-size: 24px;
    font-weight: 800;
    align-items: center;
    font-family: "roboto", sans-serif;
    margin: 30px 0;
}
.speakers-guest {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    font-size: 24px;
    font-weight: 800;
    align-items: center;
    font-family: "roboto", sans-serif;
    margin: 30px 0;
}
.speakers-trackchair span {
    font-size: 16px;
    font-weight: 400;
    color: gray;
}

.speakers-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    place-items: center;
    row-gap:2.5rem;
    column-gap: 1rem;
    padding: 1rem;
}

.speakers-gallery-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    padding: 0 0 1rem 0;
    border: 1px solid rgb(245, 241, 241);
    border-radius: 3px;
    box-shadow: 0px 5px 10px rgb(194, 191, 191);
    transition: all .3s;
}
.speakers-gallery-card img {
    position: relative;
    display: block;
    object-position: center;
    object-fit: cover;
    height:200px;
    width: 200px;
}

.speakers-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.speakers-gallery-card:hover{
    transform: scale(1.03);
}
.speakers-gallery-card:hover .speakers-overlay{
    background-color: rgb(0, 0, 0,0.4);
}

.speakers-gallery-name {
    font-weight: bold;
    font-size: 14px;
}
.speakers-gallery-role {
    position: absolute;
    max-width: 80%;
    bottom: 14%;
    background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    right: 0;
    font-size: 10px;
    font-weight: 400;
    color: white;
}
.guest {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    font-size: 24px;
    font-weight: 800;
    align-items: center;
    font-family: "roboto", sans-serif;
    margin: 30px 0;
}
.guest span {
    font-size: 16px;
    font-weight: 400;
    color: gray;
}

.guest-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:auto;
    place-items: center;
    row-gap:2.5rem;
    column-gap: 1rem;
    padding: 1rem;
}

.guest-gallery-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    padding: 0 0 1rem 0;
    border: 1px solid rgb(245, 241, 241);
    border-radius: 3px;
    box-shadow: 0px 5px 10px rgb(194, 191, 191);
    transition: all .3s;
}
.guest-gallery-card img {
    position: relative;
    display: block;
    object-position: center;
    object-fit: cover;
    height:200px;
    width: 200px;
}

.guest-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.guest-gallery-card:hover{
    transform: scale(1.03);
}
.guest-gallery-card:hover .guest-overlay{
    background-color: rgb(0, 0, 0,0.4);
}

.guest-gallery-name {
    font-weight: bold;
    font-size: 14px;
}
.guest-gallery-role {
    position: absolute;
    max-width: 80%;
    bottom: 14%;
    background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    right: 0;
    font-size: 10px;
    font-weight: 400;
    color: white;
}

.students{
   text-align: center;
   margin: 3rem 0;
}
.students span{
    color: gray;
    font-size: 16px;
    font-weight: 400;

}
.students h2{
    color: var(--text-color);
    font-size: 24px;
    font-weight: 800;
}

.students-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    place-items: center;
    row-gap:5rem;
    column-gap: 1rem;
    padding: 1rem;
}
.students-gallery-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    padding: 0 0 1.2rem 0 ;
    border: 1px solid rgb(245, 241, 241);
    border-radius: 3px;
    box-shadow: 0px 5px 10px rgb(194, 191, 191);
    transition: all .3s;
}
.students-gallery-card img {
    position: relative;
    display: block;
    object-position: center;
    object-fit: cover;
    height:200px;
    width: 180px;
}
.students-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.students-gallery-card:hover {
    transform: scale(1.03);
    
}
.students-gallery-card:hover .students-overlay  {
    background-color: rgba(0, 0, 0, 0.4);
}
.students-gallery-name {
    font-weight: bold;
    padding: 0.25rem;
    font-size: 10px;
}
.students-gallery-role {
    position: absolute;
    max-width: 80%;
    bottom: 15%;
    background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    right: 0;
    font-size: 10px;
    font-weight: 400;
    color: white;
}

@media (max-width: 640px) {
    .speakers-title {
        font-size: 30px;
        padding: 0;
    }
    .speakers-gallery {
        grid-template-columns: 1fr;
    }
    .speakers-gallery-role {
        bottom: 14%;
    }
    .guest-gallery {
        grid-template-columns: 1fr;
    }
    .guest-gallery-role {
        bottom: 14%;
    }
    .students-gallery-role {
        bottom: 14%;
    }
}
