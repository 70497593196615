@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');


:root {
  --primary-color: rgb(24, 24, 66); ;
  --secondary-color:gray;
  --text-color:  #324273;
  --nav-item-color:rgb(172, 198, 211);
  --font-color:#5A5A5A;
  --font-family-contents:'Roboto', sans-serif;
  --font-family-header:'Raleway', sans-serif
}


.html{
  box-sizing: border-box;

}
*,::after,::before{
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
body{
  font-family: 'Roboto', sans-serif;

}

.blur-load{
  background-size: cover;
  background-position: center;
  border-radius: 3px;
}
.blur-load.loaded > img{
opacity: 1;
}

.blur-load > img{
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}