.mydocument {
  width: 100%;
  height: 100dvh;
}
.document {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.document img {
  width: 80%;
  aspect-ratio: 1/1;
  display: block;
  object-fit: contain;
}
