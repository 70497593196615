.cover-poster {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height:60vh;
    background-attachment: fixed;
  }
  .cover-content{
      font-size: small;
      height: 80vh;
      width: 100%;
  }
  .cover-content >h5{
    padding: 20px;
  }
 