.shedule{
    display: flex;
    overflow-x: scroll;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.shedule-title{
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 40px;
    color: white;
    padding: 1em;
}
@media(max-width:640px){
    .shedule-title{
        font-size: 30px;
        padding: 0;
    }
}