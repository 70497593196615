

.about {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}
.about > span {
  color:var(--secondary-color) ;
  font-weight: 600;
  font-size: large;
}

.about-title {
  font-size:30px;
  font-weight: 800;
  color:var(--text-color);
  text-align: center;
  font-family: var(--font-family-header);
}
.about-content-sub{
  font-family: var(--font-family-contents);
  padding: 1rem 4rem;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  color:var(--font-color);
}
.about-contents {
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
}
.about-content {
  position: relative;
  width: 380px;
  max-width: 400px;
}

.about-content img {
  width: 100%;
  height: 100%;
  display: block;
  object-position: center;
  object-fit: cover;
  border-radius:3px;
}


.about-description {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 10;
}
.about-description > h4 {
  font-size: 0.8rem;
  font-weight: 400;
  
}
.about-description > h2 {
  font-size: 1.2rem;
  font-weight: 600;
}
.about-image-overlay { 
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  opacity: 1;
  transition: all 0.3s ease-out;
}
.about-image-overlay:hover {
  opacity: 0;
}
.proceedings-heading{
  font-size: 40px;
  color: var(--text-color);
}



@media (max-width: 640px) {
  .about-title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .about-content-sub{
    
    font-size: 16px;
  }
  .about-contents {
    padding: 1rem;
  }
  .about-content {
    width: 240px;
  }
  .about-description > h4 {
    font-size: 0.6rem;
    font-weight: 300;
  
  }
  .about-description > h2 {
    font-size: 1rem;
    font-weight: 500;
  }
  .about-quates{
    font-size:.5rem;
  }
}
