.proceedings{
    height: 100vh;
    display: flex;
}

.proceedings-title{
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 40px;
    color: white;
    padding: 1em;
}
@media(max-width:640px){
    .proceedings-title{
        font-size: 30px;
        padding: 0;
    }
}